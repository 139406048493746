import React, { useState, useEffect } from 'react';
import styles from './Main.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination , Autoplay } from 'swiper/modules';
import 'primeicons/primeicons.css';
import { useNavigate } from 'react-router-dom';
import common from '../../../common';

import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



function Main() {


    const { useAlert,useConfirm, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pConfirm, ConfirmComponent} = useConfirm();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();



    const [sliderPack, setSliderPack] = useState([]);
    const [publicationPack, setPublicationPack] = useState([]);

    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);



    const fetchData = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_main/out/get_eng_render_data`, {}, {
                withCredentials: true
            });

            if (response.data.result === 't') {
                const filteredSlideData = response.data.data_pack.slider_pack.filter(slide => slide.originLang === 'EN');
                
                const sortedSlideData = filteredSlideData.sort((a, b) => a.seq - b.seq);
                setSliderPack(sortedSlideData);

                // ===================================================

                setPublicationPack(response.data.data_pack.publication_pack);
            } else {
                pAlert(response.data.msg);;
            }
        } catch (error) {
            console.error('Fetch failed:', error);
            pAlert('실패함.');
        } finally {
            pLoadingOff();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    const slideData = [
        {
            image: '/img/mainSlide1.png',
            title: '한국공학한림원',
            content: 'The National Academy of Engineering of Korea was established to foster further innovation in engineering and technology by recognizing engineers who have made exceptional achievements. We also aim to create an environment where engineers can contribute to the country as proud leaders in various areas.',
            buttonText:'상세보기',
            buttonLink:'/greeting'
        },
        {
            image: '/img/mainSlide2.png',
            title: '제19회 청소년 과학기술도서 독후감대회 개최',
            content: '독후감 모집기간: 4월 22일(월) - 6월 3일(월)\n자세한 대회정보는 공지사항을 참고해주세요.',
            buttonText:'',
            buttonLink:''
        },
        {
            image: '/img/mainSlide3.png',
            title: '2025년 신입회원 추천 신입회원 추천신입회원 추천신입회원 추천신입회원 추천',
            content: '각 분야에서 업적이 탁월한 신입회원을 새로이 모시고자 합니다.\n자세한 정보는 공지사항을 참고해주세요.',
            buttonText:'',
            buttonLink:''
        }
    ];

    const sec1_data_1 = [
        {
            image: '/img/eng_t2-7.jpg',
            title: 'CAETS',
            link: '/en/caets?pageid=1'
        },
        {
            image: '/img/eng_t2-2.jpg',
            title: 'East Asia Round Table Meeting',
            link: '/en/caets?pageid=2'
        },
        {
            image: '/img/eng_t2-8.jpg',
            title: 'ASEAN-Korea Engineering Forum',
            link: '/en/caets?pageid=3'
        },
        {
            image: '/img/eng_t2-4.jpg',
            title: 'China-Korea Development Forum',
            link: '/en/caets?pageid=4'
        },
        {
            image: '/img/eng_t2-5.jpg',
            title: 'Korea-UK Policy and Technology Forum',
            link: '/en/caets?pageid=5'
        },
        {
            image: '/img/eng_t2-6.jpg',
            title: 'INAE-NAEK Workshop',
            link: '/en/caets?pageid=6'
        }
    ];

    const publicationData = [
        {
            title: 'NAEK VOICE',
            thumbnail: '/img/thumbnail_1.jpg',
            title : '제64호 NAEK VOICE',
            link: '/greeting'
        },
        {
            title: 'Engineering Korea',
            thumbnail: '/img/thumbnail_2.jpg',
            title : '제64호 NAEK VOICE',
            link: '/greeting'
        },
        {
            title: 'NAEK ON',
            thumbnail: '/img/thumbnail_3.jpg',
            title : '제64호 NAEK VOICE',
            link: '/greeting'
        }
    ];
    
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
    const [isMobileView2, setIsMobileView2] = useState(window.innerWidth <= 768);

    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 1024);
        setIsMobileView2(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className={styles.swiper_wrap}>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    effect="fade"
                    loop={true}
                    modules={[EffectFade, Navigation, Pagination]}
                    navigation={{
                        nextEl: `.${styles.swiper_button_next}`,
                        prevEl: `.${styles.swiper_button_prev}`,
                    }}
                    pagination={{ clickable: true }}
                    onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
                >
                    {sliderPack.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <div className={styles.main_slide_img} style={{
                                backgroundImage: `url(${slide.webServerName})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                height: '475px',
                                position: 'relative'
                            }}>
                                <div className={styles.slider_text_wrap}>
                                    <div className={styles.slider_text_title}>
                                        {slide.title}
                                    </div>
                                    <div className={styles.slider_text}>
                                        {slide.content}
                                    </div>
                                    {slide.buttonUrl && slide.buttonTitle && (
                                        <a href={slide.buttonUrl} rel="noopener noreferrer" className={styles.slider_button}>
                                            {slide.buttonTitle}
                                        </a>
                                    )}
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={styles.custom_pagination_box}>
                    <div className={styles.swiper_button_prev}>
                        <span className="pi pi-chevron-left"></span>
                    </div>
                    <div className={styles.custom_pagination}>
                        <span>{currentSlide + 1} / {sliderPack.length}</span> {/* 슬라이드 개수 자동 맞춤 */}
                    </div>
                    <div className={styles.swiper_button_next}>
                        <span className="pi pi-chevron-right"></span>
                    </div>
                </div>
            </div>










            
            <div className={styles.main_sec1}>
                <div className={styles.main_inner}>
                    <div className={styles.sec_title_box}>
                        <span className={styles.sec_title}>Introduction</span>
                    </div>
                    <div className={styles.main_img_box}>
                        <img src="/img/greeting8thEng.png" alt="" />
                    </div>

                    <div className={styles.sec_title_box}>
                        <span className={styles.sec_title}>International Activities</span>
                        <span className={styles.sec_sub_title}></span>
                    </div>

                    
                    <div className={styles.sec1_grid}>
                        {/* 768px 이하면 슬라이드 아니면 박스형식 */}
                        {!isMobileView2 ? (
                            <>
                            {sec1_data_1.map((item, index) => (
                                <a href={item.link} className={styles.sec1_box} key={index}>
                                    <div className={styles.sec1_image} style={{ backgroundImage: `url(${item.image})` }}></div>
                                    <div className={styles.sec1_text_wrap}>
                                        <div className={styles.sec1_title}>{item.title}</div>
                                        <div className={styles.sec1_content}>{item.content}</div>
                                        <div className={styles.sec1_date}>{item.date}</div>
                                    </div>
                                </a>
                            ))}
                            </>
                        ) : (
                            <>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    loop={true}
                                    modules={[EffectFade, Navigation, Pagination , Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false, // 사용자가 슬라이드를 조작해도 자동 슬라이드가 계속 작동하게 합니다.
                                    }}
                                >
                                    {sec1_data_1.map((item, index) => (
                                        <SwiperSlide key={index}>
                                            <a href={item.link} className={styles.sec1_box} key={index}>
                                                <div className={styles.sec1_image} style={{ backgroundImage: `url(${item.image})` }}></div>
                                                <div className={styles.sec1_text_wrap}>
                                                    <div className={styles.sec1_title}>{item.title}</div>
                                                    <div className={styles.sec1_content}>{item.content}</div>
                                                    <div className={styles.sec1_date}>{item.date}</div>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </>
                        )}
                    </div>
                </div>
            </div>


            




            <div className={styles.main_sec2}>
                <div className={styles.main_inner}>
                    <div className={styles.sec2_middle_grid}>



                        <div className={styles.introduction_box}>
                            <div
                                className={styles.sec2_left}
                                onClick={() => navigate('/en/greeting')}
                            >
                                <div className={styles.sec2_left_text_box}>
                                    <span className={styles.sec2_left_title}>Introduction</span>
                                </div>
                                <div className={`${styles.main_more_btn} ${styles.more_btn_style}`}>
                                    more
                                    <div className={styles.moreArrow}></div>
                                </div>
                            </div>
                            <div
                                className={styles.sec2_left}
                                onClick={() => navigate('/en/status')}
                            >
                                <div className={styles.sec2_left_text_box}>
                                    <span className={styles.sec2_left_title}>Membership</span>
                                </div>
                                <div className={`${styles.main_more_btn} ${styles.more_btn_style}`}>
                                    more
                                    <div className={styles.moreArrow}></div>
                                </div>
                            </div>
                        </div>



                        
                        <div className={styles.latest_publications_box}>
                            <div className={styles.latest_publications_row_1}>
                                <div className={styles.publication_title_box}>
                                    <span className={styles.publication_main_title}>Publication</span>
                                    <a href="/en/report" className={`${styles.main_more_btn} ${styles.m_display_none}`}>more
                                        <div className={styles.moreArrow}></div>
                                    </a>
                                </div>
                                <div className={styles.publication_items}>
                                    {/* 순서 변경: publicationPack[1], publicationPack[2], publicationPack[0] */}
                                    {publicationPack.slice(1, 3).map((publication, index) => (
                                        <a
                                            key={index}
                                            href={`noticedetail?id=${publication.idx}`}  
                                            className={styles.publication_item}
                                            style={{ backgroundImage: `url(${publication.thumbnail || '/img/symbol_1.png'})` }} 
                                            title={publication.title}
                                        >
                                            <span className={styles.publication_title}>{publication.title}</span>
                                        </a>
                                    ))}
                                    {/* 1024px 일때 최신 간행물 3개 모두 보이도록 */}
                                    {isMobileView && publicationPack[0] && (
                                        <a
                                            href={`noticedetail?id=${publicationPack[0].idx}`}  
                                            className={styles.publication_item}
                                            style={{ backgroundImage: `url(${publicationPack[0].thumbnail || '/img/symbol_1.png'})` }} 
                                            title={publicationPack[0].title}
                                        >
                                            <span className={styles.publication_title}>{publicationPack[0].title}</span>
                                        </a>
                                    )}
                                </div>
                            </div>
                            <div className={styles.latest_publications_row_2}>
                            {/* publicationPack[0]을 마지막으로 표시 */}
                            {publicationPack[0] && (
                                <a
                                    href={`noticedetail?id=${publicationPack[0].idx}`}
                                    className={styles.publication_item}
                                    style={{ backgroundImage: `url(${publicationPack[0].thumbnail || '/img/symbol_1.png'})` }}
                                    title={publicationPack[0].title}
                                >
                                    <span className={styles.publication_title}>{publicationPack[0].title}</span>
                                </a>
                            )}
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.sec2_bottom_grid}>
                        <a href="http://www.yehs.or.kr/" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner1.png" alt="" className={styles.banner_img} />
                        </a>
                        <a href="http://www.yipl.or.kr/home/" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner2.png" alt="" className={styles.banner_img} />
                        </a>
                        <a href="https://blog.naver.com/jrengineer" target='_blank' className={styles.banner_btn}>
                            <img src="/img/banner3.png" alt="" className={styles.banner_img} />
                        </a>
                    </div> */}
                </div>
            </div>
            
        </>
    );
}

export default Main;
