
import React, { useState, useEffect, useMemo } from 'react';
import { TableList } from "../../../../components";
import styles from './Contact.module.scss';
import 'primeicons/primeicons.css';

import axios from 'axios';
import common from '../../../../common';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Contact() {
    const { useAlert, useLoading } = common();
    const { pAlert, AlertComponent } = useAlert();
    const { pLoadingOn, pLoadingOff, LoadingComponent } = useLoading();

    // const [userData, setUserData] = useState([]);//
    
    const [policyTeamData, setpolicyTeamData] = useState([]);//
    const [memberTeamData, setmemberTeamData] = useState([]);//
    const [executiveTeamData, setExecutiveTeamData] = useState([]);//
    const getMember = async () => {
        try {
            pLoadingOn();
            const response = await axios.post(`${API_BASE_URL}/naek_organization/out/get_list`,
                {
                    committee_category: 'contact',
                },
                { withCredentials: true }
            );
            if (response && response.data && response.data.result === 't') {
                // setGetData(response.data.data)
                // setUserData(response.data.data)

                
                const allData = response.data.data;
                const policyTeam = allData.filter(member => member.organization.includes("Policy"));
                const memberTeam = allData.filter(member => member.organization.includes("Membership"));
                const executiveTeam = allData.filter(member => member.organization.includes("Executive"));
                setpolicyTeamData(policyTeam);
                setmemberTeamData(memberTeam);
                setExecutiveTeamData(executiveTeam);
            } else {
                pAlert(response.data.msg);
            }
        } catch (error) {
            console.error('실패:', error);
        } finally {
            setTimeout(() => {
                pLoadingOff();
            }, 300);
        }
    };
    useEffect(() => {
        getMember();
    }, []);


    const columns = useMemo(() => [
        { title: '', dataIndex: 'idx', className: 'display_none' },
        { title: 'Name', dataIndex: 'name', className: '' },
        { title: 'Affiliation', dataIndex: 'organization', className: '' },
        { title: 'Phone number', dataIndex: 'phone', className: '' },
        { title: 'Email', dataIndex: 'email', className: '' },
    ], []);

    const exColumns = useMemo(() => [
        { title: '', dataIndex: 'idx', className: 'display_none' },
        { title: 'Name', dataIndex: 'name', className: '' },
        { title: 'Affiliation', dataIndex: 'organization', className: '' },
        // { title: 'Phone number', dataIndex: 'phone', className: '' },
        { title: 'Email', dataIndex: 'email', className: '' },
    ], []);


    const pageSize = 100;
    const categoryOptions = useMemo(() => [
        { label: 'Entire', value: '전체' },
        { label: 'Name', value: 'member_name' },
    ], []);


    return (
        <>
            <LoadingComponent/>
            <div className="components_wrap">
                <div className={styles.contact_flexwrap}>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI1}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>Phone number</span>
                            <p>02-6009-4000</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI2}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>Fax number</span>
                            <p>02-6009-4019</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI3}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>e-mail</span>
                            <p>naek@naek.or.kr</p>
                        </div>
                    </div>
                    <div className={styles.contact_half}>
                        <div className={styles.contactIconWrap}>
                            <div className={styles.contactI4}></div>
                        </div>
                        <div className={styles.contactText}>
                            <span>Facebook page</span>
                            <a href="http://www.facebook.com/naek1995" target="_blank" className={styles.link}>
                                facebook.com/naek1995 <i className={`pi pi-external-link ${styles.link}`}></i>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div className={styles.member_info_text}>Staff Directory</div> */}
                <div className={styles.member_info_text}>Policy and Management Support Team</div>
                <div className={styles.table_list_box}>
                    <TableList data={policyTeamData}
                        columns={columns}
                        pageSize={pageSize}
                        categoryOptions={categoryOptions}
                        type="contact"
                        pageNav='false'
                        dtSearch='false'
                    />
                </div>
                <div className={styles.member_info_text}>Program and Membership Management Team</div>
                <div className={styles.table_list_box}>
                    <TableList data={memberTeamData}
                        columns={columns}
                        pageSize={pageSize}
                        categoryOptions={categoryOptions}
                        type="contact"
                        pageNav='false'
                        dtSearch='false'
                    />
                </div>
                <div className={styles.member_info_text}>Executive Assistance Team</div>
                <div className={styles.table_list_box}>
                    <TableList data={executiveTeamData}
                        columns={exColumns}
                        pageSize={pageSize}
                        categoryOptions={categoryOptions}
                        type="contact"
                        pageNav='false'
                        dtSearch='false'
                    />
                </div>
            </div>
        </>
    );
}

export default Contact;









