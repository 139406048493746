import React, { useState } from 'react';
import styles from './Purpose.module.scss';

function Purpose() {
    const historyData = {
        'Present~ 2010’S': [
            { date: '2025. 01', events: ['Yoon Eui-jun was appointed as the 8th president. (2025.01~Present)'] },
            { date: '2023. 05', events: ['Korean Peninsula Land Forum changed its name to Future Land Forum.'] },
            { date: '2023. 03', events: ['Park Sung-wook took office as chairman. (2023.03~Present)'] },
            { date: '2023. 01', events: ['Kim Kinam was appointed as the 7th president. (2023.01~2024.12)'] },
            { date: '2020. 10', events: ['Hosting CAETS(International Council of Academies of Engineering and Technological Sciences) Symposium and Council Meeting'] },
            { date: '2019. 06', events: ['Kim Kinam, Vice Chairman of Samsung Electronics, was appointed as chairman of the board.'] },
            { date: '2017. 01', events: ['President Kwon Oh-Kyong was elected as the president. (2017.01~2022.12)'] },
            { date: '2015. 10', events: ['The 20th anniversary of the Academy was celebrated with an international conference and other contingent programs.'] },
            { date: '2015. 06', events: ['Kwon Oh-Joon, Chairman of POSCO was appointed as chairman of the board.'] },
            { date: '2015. 01', events: ['The CEO Forum and Round Table Meeting were merged into the NAEK Forum.'] },
            { date: '2015. 01', events: ['Oh Young-Ho, President of KOTRA, was elected as the president. (2015. 01~2016. 12)'] },
            { date: '2014. 11', events: ['Korea Land Forum was launched.'] },
            { date: '2011. 06', events: ['Due to President Kim Doh-Yeon’s resignation, Chung Joon-Yang, Chairman of POSCO, was elected as the president. (2011. 06~2014. 12)'] },
            { date: '2011. 05', events: ['YIPL(Young Intellectual Property Leaders) was launched.'] },
            { date: '2011. 01', events: ['Kim Do-Yeon, the 3rd Chairman, took office. (2011. 01~2011. 03)'] },
            { date: '2010. 08', events: ['The 1st NAEK VOICE was published.'] },
        ],
        '2000’S': [
            { date: '2008. 09', events: ['The 1st NAEK Lecture was held.'] },
            { date: '2008. 06', events: ['The Energy Forum was launched.'] },
            { date: '2006. 03', events: ['A Study on policy was started as a special committee.'] },
            { date: '2005. 12', events: ['The 1st NAEK Haedong award was founded to recognize the excellence and the contributions to the development of engineering education.'] },
            { date: '2005. 10', events: ['The 10th anniversary of the Academy foundation was commemorated with the International Symposium on the Next 10 Years Technology-Oriented Society.'] },
            { date: '2005. 02', events: ['Huh Chin-Gyu was appointed to chairman of the board.'] },
            { date: '2005. 01', events: ['New President, Yun Jong-Yong was elected. (2005~2010)'] },
            { date: '2004. 06', events: ['NAEK Iljin award was founded to honor engineers who have made exceptional contributions to business, academic collaboration, and technology policy.'] },
            { date: '2004. 03', events: ['YEHS(Young Engineers Honour Society) was founded.'] },
            { date: '2004. 03', events: ['Next Generation Engineering Leaders was launched.'] },
            { date: '2003. 11', events: ['Korea Leaders forum was launched.'] },
            { date: '2003. 06', events: ['NAEK Round Table Meeting was launched.'] },
            { date: '2002. 11', events: ['Moved the Office to Korea Technology Center in Gangnam, Seoul.'] },
            { date: '2002. 03', events: ['Changed the name from "Korea Engineering & Technology Award" to "National Academy of Engineering of Korea".'] },
            { date: '2002. 03', events: ['Published The New Encounters Series with Engineering.'] },
            { date: '2001. 09', events: ['Launched the CEO Breakfast Discussion Meeting, a monthly gathering to exchange business information and share experiences.'] },
            { date: '2000. 10', events: ['NAEK became a member of CAETS, an independent nonpolitical, non-governmental international organization of engineering and technological science'] },
        ],
        '1990’S': [
            { date: '1999. 01', events: ['Renamed to Korea Institute of Engineering'] },
            { date: '1998. 09', events: ['Yun Jong-Yong was elected as chairman of the board.'] },
            { date: '1998. 09', events: ['The CEO Forum was launched to invite distinctive speakers to discuss contemporary issues in engineering and technology.'] },
            { date: '1997. 11', events: ['East Asia Round Table Meeting was launched.'] },
            { date: '1997. 03', events: ['The 1st NAEK Award Ceremony was held to recognize outstanding engineers for their engineering achievements.'] },
            { date: '1996. 06', events: ['The Inaugural Meeting was held and Professor Lee Ki-Jun at Seoul National University was appointed as the 1st president of the Academy.'] },
            { date: '1996. 04', events: ['The Board of Directors were constituted and Lee Chong-Hun (KEPCO President) was elected as chairman of the board.'] },
            { date: '1996. 04', events: ['The Board approved of 108 members and 32 honorary members.'] },
            { date: '1995. 10', events: ['Ministry of Industry and Energy approved the institution as an incorporated foundation.'] },
            { date: '1994. 12', events: ['The Act of “Building Infrastructure of Engineering Technology” was officially announced to be effective from July 1st, 1995'] },
            { date: '1994. 10', events: ['The Proposal on the article of “Building Infrastructure for Industrial Technology” was agreed at the National Assembly.'] },
            { date: '1994. 07', events: ['Announced the legislation on the creation of industrial technology infrastructure, including the establishment of the Korea Institute of Engineering'] },
            { date: '1993. 12', events: ['The Korean Association for Engineering Deans suggested the government to establish the national Academy in engineering field.'] },
        ],
    };

    const [activePeriod, setActivePeriod] = useState(Object.keys(historyData)[0]);


    const renderHistory = (data) => {
        const groupedData = [];
        let currentGroup = null;

        data.forEach((item) => {
            const year = item.date.split('.')[0].trim();
            if (currentGroup && currentGroup.year === year) {
                currentGroup.items.push(item);
            } else {
                currentGroup = {
                    year: year,
                    items: [item]
                };
                groupedData.push(currentGroup);
            }
        });

        // return groupedData.map((group, index) => (
        //     <div className={styles.pp_box} key={index}>
        //         <div className={styles.pp_box_le_box}>
                    // {group.items.map((item, idx) => (
                    //     <span key={idx}>{idx === 0 ? item.date : item.date.split('.')[1]}</span>
                    // ))}
        // //         </div>
        //         <div className={styles.pp_box_ri_box}>
        //             {group.items.map((item) => (
        //                 item.events.map((event, eventIdx) => (
        //                     <span key={eventIdx}>{event}</span>
        //                 ))
        //             ))}
        //         </div>
        //     </div>
        // ));



        return groupedData.map((group, index) => (
            <div className={styles.pp_box} key={index}>
                {group.items.map((item, idx) => (
                    <div key={idx} className={styles.pp_box_item}>
                        {/* 첫 번째 항목에만 특별한 스타일 추가 */}
                        <span className={`${styles.date} ${index === 0 && idx === 0 ? styles.firstDate : ''}`}>
                            {idx === 0 ? item.date : item.date.split('.')[1]}
                        </span>
                        {item.events.map((event, eventIdx) => (
                            <span
                                key={eventIdx}
                                className={`${styles.event} ${index === 0 && idx === 0 ? styles.firstEvent : ''}`}
                            >
                                {event}
                            </span>
                        ))}
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <>
            <div className="components_wrap">
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>Objectives:</span>
                    </div>
                    <div className="cont_box">
                        <p>· Conduct research and propose policies regarding engineering technologies.</p>
                        <p>· Strengthen international activities related to engineering technologies through the international network of the International Council of Academies of Engineering and Technological Sciences (CAETS), the East Asia Round Table Meetings, etc.</p>
                        <p>· Create and promote an engineering culture.</p>
                        <p>· Acknowledge and honor engineers who have brought innovation to society.</p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>History</span>
                    </div>

                    {Object.keys(historyData).map((period, idx) => (
                        <div key={idx}>
                            <div className={styles.pp_date_btn}>
                                {Object.keys(historyData).map((innerPeriod, innerIdx) => (
                                    <button
                                        key={innerIdx}
                                        className={innerPeriod === period ? styles.pp_date_btn_active : ''}
                                        onClick={() => {
                                            setActivePeriod(innerPeriod);
                                            const section = document.getElementById(`section-${innerIdx}`);
                                            if (section) {
                                                const yOffset = -250; // 100px 위로 스크롤
                                                const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                                window.scrollTo({ top: y, behavior: 'smooth' });
                                            }
                                        }}
                                    >
                                        {innerPeriod}
                                    </button>
                                ))}
                            </div>
                            <div className={styles.purpose_wrap} id={`section-${idx}`}>
                                <div className={`${styles.purpose_list} cont_box`}>
                                    <div className={styles.pp_wrap}>
                                        {renderHistory(historyData[period])}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Purpose;