
import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './RelatedOrganization.module.scss';
import {TableCard} from "../../../../components";
import { FaRegShareFromSquare } from "react-icons/fa6";


function RelatedOrganization() {

    const data = useMemo(() => [
        {
            idx:0,
            title:"CAETS",
            img:"/img/eng/KakaoTalk_20241216_091758827.webp",
            link:"https://www.newcaets.org/",
        }, 
        {
            idx:1,
            title:"Academia Nacional de Ingenieria (ANI)",
            img:"/img/eng/Academia Nacional de Ingenieria (ANI).jpg",
            link:"http://www.acading.org.ar",
         },
         {
            idx:2,
            title:"Australian Academy of Technological Sciences and Engineering (ATSE)",
            img:"/img/eng/Australian Academy of Technological Sciences and Engineering (ATSE).png",
            link:"http://www.atse.org.au",
         },
         {
            idx:3,
            title:"Royal Belgian Academy Council of Applied Sciences (BACAS)",
            img:"/img/eng/Royal Belgian Academy Council of Applied Sciences (BACAS).png",
            link:"http://www.kvab.be",
         },
         {
            idx:4,
            title:"Canadian Academy of Engineering (CAE)",
            img:"/img/eng/Canadian Academy of Engineering (CAE).png",
            link:"http://www.cae-acg.ca",
         },
         {
            idx:5,
            title:"Chinese Academy of Engineering (CAE)",
            img:"/img/eng/Chinese Academy of Engineering (CAE).png",
            link:"http://www.cae.cn",
         },
         {
            idx:6,
            title:"Croatian Academy of Engineering (HATZ)",
            img:"/img/eng/Croatian Academy of Engineering (HATZ).png",
            link:"http://www.hatz.hr",
         },
         {
            idx:7,
            title:"Engineering Academy of the Czech Republic (EACR)",
            img:"/img/eng/Engineering Academy of the Czech Republic (EACR).png",
            link:"http://www.eacr.cz",
         },
         {
            idx:8,
            title:"Danish Academy of Technical Sciences (ATV)",
            img:"/img/eng/Danish Academy of Technical Sciences (ATV).png",
            link:"http://www.atv.dk",
         },
         {
            idx:9,
            title:"Council of Finnish Academies (CoFA)",
            img:"/img/eng/Council of Finnish Academies (CoFA).png",
            link:"http://www.academies.fi",
         },
         {
            idx:10,
            title:"National Academy of Technologies of France (NATF)",
            img:"/img/eng/National Academy of Technologies of France (NATF).png",
            link:"http://www.academie-technologies.fr",
         },
         {
            idx:11,
            title:"National Academy of Science and Engineering (acatech)",
            img:"/img/eng/National Academy of Science and Engineering (acatech).png",
            link:"http://www.acatech.de",
         },
         {
            idx:12,
            title:"Hungarian Academy of Engineering (HAE)",
            img:"/img/eng/Hungarian Academy of Engineering (HAE).png",
            link:"http://www.mernokakademia.hu",
         },
         {
            idx:13,
            title:"Indian National Academy of Engineering (INAE)",
            img:"/img/eng/Indian National Academy of Engineering (INAE).png",
            link:"http://www.inae.in",
         },
         {
            idx:14,
            title:"Irish Academy of Engineering (IAE)",
            img:"/img/eng/Irish Academy of Engineering (IAE).png",
            link:"http://www.iae.ie",
         },
         {
            idx:15,
            title:"Engineering Academy of Japan (EAJ)",
            img:"/img/eng/Engineering Academy of Japan (EAJ).png",
            link:"http://www.eaj.or.jp" ,
         },
        //  {
        //     idx:17,
        //     title:"Academy of Engineering of Mexico (AIM)",
        //     img:"/img/eng/Academy of Engineering of Mexico (AIM).png",
        //     link:"http://www.ai.org.mx",
        //  },
         {
            idx:18,
            title:"Netherlands Academy of Engineering (NAE)",
            img:"/img/eng/Netherlands Academy of Engineering (NAE).jpg",
            link:"http://www.nae.nl/en",
         },
         {
            idx:19,
            title:"Royal Society Te Aparangi",
            img:"/img/eng/Royal Society Te Aparangi.png",
            link:"http://www.royalsociety.org.nz",
         },
         {
            idx:20,
            title:"Nigerian Academy of Engineering (NAE)",
            img:"/img/eng/Nigerian Academy of Engineering (NAE).png",
            link:"https://www.nae.ng/",
         },
         {
            idx:21,
            title:"Norwegian Academy of Technological Sciences (NTVA)",
            img:"/img/eng/Norwegian Academy of Technological Sciences (NTVA).png",
            link:"http://www.ntva.no",
         },
         {
            idx:22,
            title:"Pakistan Academy of Engineering (PAE)",
            img:"/img/eng/Pakistan Academy of Engineering (PAE).png",
            link:"http://www.pacadengg.org",
         },
         {
            idx:23,
            title:"Academy of Engineering of Poland (AIP)",
            img:"/img/eng/Academy of Engineering of Poland (AIP).jpg",
            link:"https://akademiainzynierska.pl/index.php/english/",
         },
         {
            idx:24,
            title:"Academy of Engineering Sciences of Serbia (AESS)",
            img:"/img/eng/Academy of Engineering Sciences of Serbia (AESS).png",
            link:"http://www.ains.rs",
         },
         {
            idx:25,
            title:"Academy of Engineering: Singapore (SAEng)",
            img:"/img/eng/Academy of Engineering Singapore (SAEng).png",
            link:"https://www.saeng.sg/",
         },
         {
            idx:26,
            title:"Slovenian Academy of Engineering (IAS)",
            img:"/img/eng/Slovenian Academy of Engineering (IAS).jpg",
            link:"http://www.ias.si",
         },
         {
            idx:27,
            title:"South African Academy of Engineering (SAAE)",
            img:"/img/eng/South African Academy of Engineering (SAAE).jpg",
            link:"http://www.saae.co.za",
         },
         {
            idx:28,
            title:"Real Academia de Ingenieria (RAI)",
            img:"/img/eng/Real Academia de Ingenieria (RAI).png",
            link:"http://www.raing.es",
         },
         {
            idx:29,
            title:"Royal Swedish Academy of Engineering Sciences (IVA)",
            img:"/img/eng/Royal Swedish Academy of Engineering Sciences (IVA).png",
            link:"http://www.iva.se",
         },
         {
            idx:30,
            title:"Swiss Academy of Engineering Sciences (SATW)",
            img:"/img/eng/Swiss Academy of Engineering Sciences (SATW).png",
            link:"http://www.satw.ch",
         },
         {
            idx:31,
            title:"Royal Academy of Engineering (RAEng)",
            img:"/img/eng/Royal Academy of Engineering (RAEng).png",
            link:"http://www.raeng.org.uk",
         },
         {
            idx:32,
            title:"National Academy of Engineering (NAE)",
            img:"/img/eng/National Academy of Engineering (NAE).png",
            link:"http://www.nae.edu",
         },
         {
            idx:33,
            title:"National Academy of Engineering of Uruguay (ANIU)",
            img:"/img/eng/National Academy of Engineering of Uruguay (ANIU).png",
            link:"http://www.aniu.org.uy",
         },
    ], []);

    return (
        <>
            <div className="components_wrap">
                <div className={styles.top_content_wrap}>
                    <div className={styles.grid_container}>
                    {data.map((item) => (
                        <a key={item.idx} href={item.link} className={styles.card_link} target="_blank" rel="noopener noreferrer">
                            <div className={styles.card}>
                                <img src={item.img} alt={item.link} className={styles.logo} />
                                <h3 className={styles.card_title}>{item.title}</h3>
                                <div className={styles.link_button_box}>
                                    <span className={styles.link_button}>
                                        <FaRegShareFromSquare /> direct link
                                    </span>
                                </div>
                            </div>
                        </a>
                    ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default RelatedOrganization;