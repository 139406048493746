
import styles from './Greeting.module.scss';

function Greeting() {
    return (
        <>
            <div className="components_wrap">
                <div className="cont_img_wrap">
                    <img src="/img/greeting8thEng.png" className='cont_img_de' alt="" style={{ width: '100%', }} />
                    <img src="/img/greeting8thEng.png" className='cont_img_m' alt="" style={{ width: '100%', }} />
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>Greeting</span>
                    </div>
                    <div className="cont_box">
                        <p>The National Academy of Engineering of Korea aims to discover and acknowledge engineers who have made remarkable contributions to the technological development in universities, companies, and research institutes, and to contribute to Korea’s creative engineering technology development through academic research and supporting projects.</p>
                        <p>We will put together our knowledge for the innovation of Korean industry and engineering technology. As a leading group of engineering technology, NAEK will be a faithful mediator between government and society.</p>
                        <p>In addition, NAEK will propose effective policies on social issues related to industrial and engineering and technology and create sound public opinion.</p>
                        <p>The future of Korea depends on fostering commendable talents in science and technology. NAEK will promote its program to foster talent, including the Junior Engineering Technology Classes, the Campus Patent Strategy Universiade, and the Young Engineers Honor Society. NAEK will share its vision of the future with students and create an environment where engineers can contribute to the country as proud leaders in many areas.</p>
                        <p>With a vision of a global engineering technology platform, NAEK will play a leading role in creating a sustainable and growing society, a creative and smart society, and a healthy and safe society.</p>
                        <p>We appreciate your interest and continuous support.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Greeting;