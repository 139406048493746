
const chairmanData = [
    {
        img: "/img/이사장/이종훈.jpg",
        title: "제1대 이사장 이종훈",
        description1: "前 한국전력공사 사장",
        description2: "1996 - 1998"
    },
    {
        img: "/img/이사장/윤종용.jpg",
        title: "제2대 이사장 윤종용",
        description1: "前 삼성전자 부회장",
        description2: "1999 - 2004"
    },
    {
        img: "/img/이사장/허진규.jpg",
        title: "제3대 이사장 허진규",
        description1: "일진전기 회장",
        description2: "2005 - 2015.06"
    },
    {
        img: "/img/이사장/권오준.jpg",
        title: "제4대 이사장 권오준",
        description1: "前 포스코 회장",
        description2: "2015.06 - 2019.6"
    },
    {
        img: "/img/이사장/김기남.jpg",
        title: "제5대 이사장 김기남",
        description1: "삼성전자 종합기술원 회장",
        description2: "2019.6 - 2022.12"
    },
    {
        img: "/img/이사장/박성욱.png",
        title: "제6대 이사장 박성욱",
        description1: "SK하이닉스 고문",
        description2: "2022.12 - 현재"
    }
];

const presidentData = [
    {
        img: "/img/이사장/이기준.jpg",
        title: "제1대 회장 이기준",
        description1: "前 서울대학교 총장",
        description2: "1996 - 2004"
    },
    {
        img: "/img/이사장/윤종용.jpg",
        title: "제2대 회장 윤종용",
        description1: "前 삼성전자 부회장",
        description2: "2005 - 2010"
    },
    {
        img: "/img/이사장/김도연.jpg",
        title: "제3대 회장 김도연",
        description1: "前 POSTECH 총장",
        description2: "2011 - 2011.03"
    },
    {
        img: "/img/이사장/정준양.jpg",
        title: "제4대 회장 정준양",
        description1: "前 포스코 회장",
        description2: "2011.07 - 2014"
    },
    {
        img: "/img/이사장/오영호.jpg",
        title: "제5대 회장 오영호",
        description1: "前 KOTRA 회장",
        description2: "2015 - 2016"
    },
    {
        img: "/img/이사장/권오경.jpg",
        title: "제6대 회장 권오경",
        description1: "한양대학교 석좌교수",
        description2: "2017 - 2022"
    },
    {
        img: "/img/이사장/김기남.jpg",
        title: "제7대 회장 김기남",
        description1: "삼성전자 SAIT 회장",
        description2: "2023 - 2024"
    },
    {
        img: "/img/이사장/윤의준.jpg",
        title: "제8대 회장 윤의준",
        description1: "서울대학교 특임교수",
        description2: "2025 - 현재"
    }
];

function Chairman() {
    return (
        <>
            <div className="components_wrap">
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>역대 이사장</span>
                    </div>
                    <div className="cm_cont_box">
                        <div className="cm_cont_img_wrap">
                            {chairmanData.map((item, index) => (
                                <div className="cont_img_box" key={index}>
                                    <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                    <p className='cm_txt_1'>{item.title}</p>
                                    <p className='cm_txt_2'>{item.description1}</p>
                                    <p className='cm_txt_3'>{item.description2}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>역대 회장</span>
                    </div>
                    <div className="cm_cont_box">
                        <div className="cm_cont_img_wrap">
                            {presidentData.map((item, index) => (
                                <div className="cont_img_box" key={index}>
                                    <img src={item.img} className='' alt="" style={{ width: '100%', height: 'auto' }} />
                                    <p className='cm_txt_1'>{item.title}</p>
                                    <p className='cm_txt_2'>{item.description1}</p>
                                    <p className='cm_txt_3'>{item.description2}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Chairman;