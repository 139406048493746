import styles from './Ci.module.scss';
import { FaDownload } from 'react-icons/fa';
function Ci() {

    // 한국공학한림원의 30주년을 상징하는 엠블럼은 숫자 30을 형상화였습니다. NAEK 로고 컬러인 블루를 메인으로 하였으며, 여기에 메탈릭한 
    // 느낌을 주기 위해 그라데이션 처리를 하였습니다. ‘3’과 연결되는 ‘0’, 또 그 안의 선들은 공학의 여러 분야가 서로 연결되어 시너지를 창출한다는 
    // 의미와 미래지향적 가치를 담고 있습니다. 회로 패턴과 육각형의 모습도 공학의 특성을 나타내며, 또한 NAEK 로고와 함께 시작 연도를 넣어 
    // 한국공학한림원의 역사를 반영했습니다. 



    // 한국공학한림원 30주년 기념 슬로건은 ‘혁신을 주도하며, 미래를 변화시켜 가겠다’는 의지를 담고 있습니다. 이 의미를 담아 워드마크는 영문과 
    // 국문을 조합하여 디자인하였습니다. ‘INNOVATE’는 이중 라인으로 현대적인 감각으로 공학 기술의 정교함을 담았으며, ‘Tomorrow’는 엠블럼 
    // 컬러에 맞춰 메탈릭한 느낌을 더해 통일감을 주었습니다. 영문 워드마크가 메인이며, 필요에 따라 국문은 제외하고 영문만 사용 가능합니다. 
    // 하지만 국문만 단독으로 사용할 수는 없습니다.
    
    return (
        <>
            <div className="components_wrap">
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>엠블럼</span>
                    </div>
                    <div className="cont_box">
                        <div className={`${styles.logo_img_box} cont_img_box`}>
                            <img src="/img/30주년_엠블럼_메인.jpg" className='' alt="" style={{}} />
                            <img src="/img/30주년_엠블럼_체크배경.png" className='' alt="" style={{}} />
                        </div>
                        <p>한국공학한림원의 30주년을 상징하는 엠블럼은 숫자 30을 형상화였습니다. </p>
                        <p>NAEK 로고 컬러인 블루를 메인으로 하였으며, 여기에 메탈릭한 느낌을 주기 위해 그라데이션 처리를 하였습니다</p>
                        <p>‘3’과 연결되는 ‘0’, 또 그 안의 선들은 공학의 여러 분야가 서로 연결되어 시너지를 창출한다는 의미와 미래지향적 가치를 담고 있습니다.</p>
                        <p>회로 패턴과 육각형의 모습도 공학의 특성을 나타내며, 또한 NAEK 로고와 함께 시작 연도를 넣어 한국공학한림원의 역사를 반영했습니다. </p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>슬로건</span>
                    </div>
                    <div className={`${styles.logo_img_2} cont_box`}>
                        <div className={`${styles.logo_img_box_2}`}>
                            <div className={`${styles.border_2}`}>
                                <img src="/img/슬로건_메인.jpg" className='' alt="" style={{}} />
                            </div>
                        </div>
                        <div className={`${styles.logo_img_box_2}`}>
                            <div className={`${styles.border_2}`}>
                                <img src="/img/슬로건_메인_체크배경.png" className='' alt="" style={{}} />
                            </div>
                        </div>
                    </div>
                    <p className={styles.tmp_sl_p}>한국공학한림원 30주년 기념 슬로건은 ‘혁신을 주도하며, 미래를 변화시켜 가겠다’는 의지를 담고 있습니다. 이 의미를 담아 워드마크는 영문과 국문을 조합하여 디자인하였습니다.</p>
                    <p className={styles.tmp_sl_p}>‘INNOVATE’는 이중 라인으로 현대적인 감각으로 공학 기술의 정교함을 담았으며, ‘Tomorrow’는 엠블럼 컬러에 맞춰 메탈릭한 느낌을 더해 통일감을 주었습니다.</p>
                    <p className={styles.tmp_sl_p}>영문 워드마크가 메인이며, 필요에 따라 국문은 제외하고 영문만 사용 가능합니다. 하지만 국문만 단독으로 사용할 수는 없습니다.</p>
                </div>
                <div className={`${styles.ci_btn_wrap}`}>
                    <div className={`${styles.ci_btn_box}`}>
                        <a href="/img/한국공학한림원_30주년_BI_매뉴얼.pdf" download className={`p_btn p_btn_normal`}>
                            <span>매뉴얼 다운로드</span>
                            <FaDownload className={`${styles.ci_icon}`} />
                        </a>
                    </div>
                    <div className={`${styles.ci_btn_box}`}>
                        <a href="/img/30주년_CI.zip" download className={`p_btn p_btn_normal`}>
                            <span>CI 파일 다운로드</span>
                            <FaDownload className={`${styles.ci_icon}`} />
                        </a>
                    </div>
                </div>

                {/* ============================ */}

                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>로고</span>
                    </div>
                    <div className="cont_box">
                        <div className={`${styles.logo_img_box} cont_img_box`}>
                            <img src="/img/symbol1-1.png" className='' alt="" style={{}} />
                            <img src="/img/symbol1-2.png" className='' alt="" style={{}} />
                        </div>
                        <p>우리 로고는 영문 명칭(The National Academy of Engineering of Korea)의 이니셜인 ‘NAEK’로 이루어져 있습니다.</p>
                        <p>현대적인 감각과 느낌을 표현하며 가시성을 향상시킨 심플한 레터마크 로고입니다.</p>
                    </div>
                </div>
                <div className="cont_wrap">
                    <div className="cont_tit_box">
                        <span>로고 타입</span>
                    </div>
                    <div className={`${styles.logo_img_2} cont_box`}>
                        <div className={`${styles.logo_img_box_2}`}>
                            <div className={`${styles.border}`}>
                                <img src="/img/symbol2.png" className='' alt="" style={{}} />
                            </div>
                            <p>한국공학한림원의 로고와 국문 명칭을 조합한 CI</p>
                        </div>
                        <div className={`${styles.logo_img_box_2}`}>
                            <div className={`${styles.border}`}>
                                <img src="/img/symbol3.png" className='' alt="" style={{}} />
                            </div>
                            <p>한국공학한림원의 로고와 국영문 명칭을 조합한 CI</p>
                        </div>
                    </div>
                </div>
                <div className={`${styles.ci_btn_wrap}`}>
                    <div className={`${styles.ci_btn_box}`}>
                        <a href="/img/NAEK_CI_PACK.zip" download className={`p_btn p_btn_normal`}>
                            <span>CI 이미지 다운로드</span>
                            <FaDownload className={`${styles.ci_icon}`} />
                        </a>
                    </div>
                    <div className={`${styles.ci_btn_box}`}>
                        <a href="/img/NAEK_Ci.ai" download className={`p_btn p_btn_normal`}>
                            <span>CI AI 파일 다운로드</span>
                            <FaDownload className={`${styles.ci_icon}`} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Ci;